import React from "react";
import {FooterTabFragment} from "../../shared/FooterTabFragment";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {colorEarthRed} from "../../shared/colors";
import {Action} from "../../shared/types";
import {NewGameHelper, renderGameButton} from "./EditGameHelper";

export class HomeFragment extends FooterTabFragment {

  renderContent(): React.ReactElement {
    return <StyledBoxColumn
      style={{flexGrow: 1, alignItems: "center", justifyContent: "center", position: "relative"}}>
      {/*<HeroImage/>*/}
      <div style={{position: "absolute", width: "100%", height: "100%", background: "white", opacity: 0.8}}/>
      <StyledBoxColumn>
        {renderGameButton(new Action("Play solo", () => new NewGameHelper(this.props.path).createNewSoloGame()))}
        {/*{renderGameButton(new Action("Play a challenge", () => new NewGameHelper(this.props.path).createNewChallengeGame()), colorEarthRed)}*/}
      </StyledBoxColumn>
    </StyledBoxColumn>;
  }
}