import React, {ReactElement} from "react";
import {QuestionSet} from "../types";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../shared/BaseFragment";
import {StyledBoxColumn, StyledSpan} from "../../shared/StyledComponents";
import {Button, Card, Typography} from "@mui/material";
import {BORDER_RADIUS, PD_MD, PD_XLG} from "../../shared/dimens";
import {COLOR_PRIMARY} from "../App";
import {colorGreen, colorRed, translucentBlack} from "../../shared/colors";
import {ProgressView} from "./ProgressView";
import {Action} from "../../shared/types";
import {GameDifficultyLevel, NewPageHelper} from "./EditGameHelper";

function HeroImage(props: { done: boolean, win?: boolean }): ReactElement {
  // const src = !props.done ? "/images/hero.png" : (props.win ? "/images/win.png" : "/images/lose.png");
  const src = "/images/hero.png";
  return <img src={src}
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: 0.5
              }}/>;
}

export type GameFragmentProps = BaseFragmentProps & {
  initialDifficultyLevel: GameDifficultyLevel,
}

type GameFragmentState = BaseFragmentState & {
  level: GameDifficultyLevel,
  questionSet: QuestionSet,
  index: number,
  correctAnswers: number,
  selectedOptionIndex?: number,
  startAt?: number,
  selectedAt?: number,
  timedOut?: boolean,
  done?: boolean,
  now: number,
}

const SELECT_INTERVAL = 10_000; // 10 sec
const NEXT_INTERVAL: number = 1000; // 1 sec
const MAX_QUESTIONS = 10;

export class GameSoloFragment extends BaseFragment<GameFragmentProps, GameFragmentState> {

  protected onCreateState(): GameFragmentState {
    return {
      ...super.onCreateState(),
      level: this.props.initialDifficultyLevel,
    };
  }

  private readonly actions: Action[] = [
    new Action("Play again", () => {
      new NewPageHelper(this.props.path, null).updateGame(level => {
        this.setState({
          level: level,
        });
        this.reload(true);
      })
    }),
  ];

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const all = (await fetch("/data/00.json").then(resp => resp.json())) as QuestionSet;
    const questionSet = [];
    while (questionSet.length < MAX_QUESTIONS) {
      questionSet.push(all.splice(Math.floor(Math.random() * all.length), 1)[0]);
    }
    this.setState({
      questionSet: questionSet,
      index: -1,
      correctAnswers: 0,
      selectedOptionIndex: undefined,
      startAt: undefined,
      selectedAt: undefined,
      timedOut: undefined,
      done: undefined,
      now: Date.now() - 1,
    });
    setTimeout(() => this.gotoNext(), 1);
  }

  componentDidUpdate(prevProps: Readonly<GameFragmentProps>, prevState: Readonly<GameFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (!this.state.timedOut && !this.state.done && (prevState.now !== this.state.now)) {
      if (this.state.selectedAt) {
        const diff = this.state.now - this.state.selectedAt;
        if (diff >= NEXT_INTERVAL) {
          this.gotoNext();
        }
      } else {
        const diff = this.state.now - this.state.startAt;
        if (diff >= SELECT_INTERVAL) {
          this.setState({
            timedOut: true,
          });
          setTimeout(() => this.gotoNext(), 1500);
        }
      }
      requestAnimationFrame(() => {
        this.setState({
          now: Date.now(),
        });
      });
    }
  }

  private gotoNext(): void {
    const now = Date.now();
    const index = this.state.index + 1;
    this.setState({
      index: index,
      selectedOptionIndex: undefined,
      startAt: now,
      selectedAt: undefined,
      now: now,
      done: index >= this.state.questionSet?.length,
      timedOut: false,
    });
  }

  private renderActionButton(action: Action) {
    return <Button onClick={action.onClick}>
      {action.text}
    </Button>;
  }

  private renderOptionButton(option: string, index: number) {
    const didSelect = this.state.selectedOptionIndex >= 0;
    if (didSelect && this.state.selectedOptionIndex === index) {
      const correct = this.state.selectedOptionIndex === this.state.questionSet[this.state.index].answer;
      return <div style={{position: "relative"}}>
        <Button
          variant="contained"
          style={{
            width: "100%",
            textOverflow: "ellipsis",
            overflowX: "auto",
            textWrap: "nowrap",
            background: correct ? colorGreen : colorRed
          }}>
          {option}
        </Button>
        <div style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: Math.min(100, Math.max(0, ((Date.now() - this.state.selectedAt) * 110 / NEXT_INTERVAL))) + "%",
          height: "100%",
          background: translucentBlack
        }}/>
      </div>
    }
    return <Button
      onClick={() => {
        const correct = index === this.state.questionSet[this.state.index].answer;
        this.setState({
          selectedOptionIndex: index,
          correctAnswers: this.state.correctAnswers + (correct ? 1 : 0),
          selectedAt: Date.now(),
        });
      }}
      disabled={didSelect || this.state.timedOut}
      style={{
        textOverflow: "ellipsis",
        overflowX: "auto",
        textWrap: "nowrap",
      }}>{option}</Button>;
  }

  protected renderContainerContent(): React.ReactElement | null {
    if (!Number.isInteger(this.state.index) || this.state.index < 0 || !this.state.startAt) {
      return;
    }
    const done = this.state.done;
    const item = !done ? this.state.questionSet[this.state.index] : undefined;
    const aspectRatio = window.innerWidth / window.innerHeight;
    const winCount = Math.floor(this.state.questionSet.length * this.state.level.fraction);
    const win = this.state.correctAnswers >= winCount;
    return <div style={{position: "relative", width: "100%", height: "100%", backgroundColor: COLOR_PRIMARY}}>
      <div style={{position: "absolute", width: "100%", height: "100%", backgroundColor: "black", opacity: 0.5}}/>
      <StyledBoxColumn style={{
        boxSizing: "border-box",
        height: "100%",
        maxWidth: 640,
        margin: "auto",
        padding: aspectRatio < 1 ? 0 : PD_MD,
      }}>
        <Card style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: BORDER_RADIUS,
          flexGrow: 1,
          background: "white",
        }}>
          <StyledBoxColumn style={{position: "relative", flexGrow: 1, background: COLOR_PRIMARY, color: "white"}}>
            <div style={{position: "absolute", top: 12, left: 12, right: aspectRatio < 1 ? 64 : 12, padding: 2}}>
              <ProgressView value0={this.state.index}
                            value1={this.state.correctAnswers}
                            winCount={winCount}
                            done={this.state.done}
                            win={win}
                            max={this.state.questionSet.length}/>
            </div>
            <HeroImage done={this.state.done} win={win}/>
            <StyledSpan/>
            {!done
              ? <Typography variant="h4" style={{
                zIndex: 1,
                padding: PD_XLG,
                paddingTop: 128,
                background: "linear-gradient(transparent 25%, rgba(0,0,0,0.5))"
              }}>
                {item.question}
              </Typography>
              : null}
          </StyledBoxColumn>
          <StyledBoxColumn>
            <div style={{position: "relative", height: 4}}>
              <div style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: Math.min(100, Math.max(0, (((this.state.selectedAt || Date.now()) - this.state.startAt) * 100 / SELECT_INTERVAL))) + "%",
                height: "100%",
                background: COLOR_PRIMARY
              }}/>
            </div>
          </StyledBoxColumn>
          <StyledBoxColumn style={{padding: PD_XLG, height: 240}}>
            <StyledSpan/>
            {!done
              ? item.options.map((option, index) => this.renderOptionButton(option, index))
              : <>
                <Typography style={{fontFamily: "Trivia King", fontSize: "400%", color: COLOR_PRIMARY, textAlign: "center"}}>{win ? "You win!" : "You lose."}</Typography>
                {this.actions.map(action => this.renderActionButton(action))}
              </>}
            <StyledSpan/>
          </StyledBoxColumn>
        </Card>
      </StyledBoxColumn>
    </div>;
  }
}