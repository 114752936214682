import {Action} from "../../shared/types";
import {Button, TextField} from "@mui/material";
import {DW_XS, PD_XXLG} from "../../shared/dimens";
import React, {ReactElement, useState} from "react";
import {App} from "../App";
import {DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE, DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN} from "../../shared/BaseApp";
import {CustomDialogContent} from "../../shared/Dialogs";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {getMemberAuth} from "../../shared/auth";
import {MAX_LETTERS, Room, ROOM_CODE_LENGTH, RoomJoin, RoomJoins, Rooms} from "../types";
import {passkey} from "../../shared/passkey";
import {PathProps} from "../../index";
import {GameChallengeFragment} from "./GameChallengeFragment";
import {GameSoloFragment} from "./GameSoloFragment";

export function renderGameButton(action: Action) {
  return <Button
    style={{
      paddingLeft: PD_XXLG,
      paddingRight: PD_XXLG,
    }}
    variant="outlined"
    onClick={action.onClick}>{action.text}</Button>
}

const DELAY = 1;

export class GamesLoader {

  private static instance: GamesLoader;

  static getInstance() {
    if (!this.instance) {
      this.instance = new GamesLoader();
    }
    return this.instance;
  }

  private readonly gamesByDifficultyMap = new Map<GameDifficultyLevel, string[]>();

  private constructor() {
  }

  async load(level: GameDifficultyLevel): Promise<string[]> {
    let words: string[] = this.gamesByDifficultyMap.get(level);
    if (words == null) {
      words = (await fetch("/" + level.filename).then(result => result.text())).trim().split("\n");
      this.gamesByDifficultyMap.set(level, words);
    }
    return words;
  }

  async getRandomWord(difficultyLevel: GameDifficultyLevel): Promise<string> {
    const games = await this.load(difficultyLevel);
    return games[Math.floor(games.length * Math.random())];
  }
}

export class GameDifficultyLevel {

  static readonly VALUES = new Array<GameDifficultyLevel>(4);
  static readonly EASY = GameDifficultyLevel.VALUES[0] = new GameDifficultyLevel("easy", "Easy", "Easy desc", 0.4, "words/easy.txt");
  static readonly MEDIUM = GameDifficultyLevel.VALUES[1] = new GameDifficultyLevel("medium", "Medium", "Medium desc", 0.6, "words/medium.txt");
  static readonly HARD = GameDifficultyLevel.VALUES[2] = new GameDifficultyLevel("hard", "Hard", "Hard desc", 0.8, "words/hard.txt");

  constructor(readonly name: string, readonly text: string, readonly description: string, readonly fraction: number, readonly filename: string) {
  }
}

function EnterRoomCodeView(props: { onRoomCodeEntered: (code: string) => void }): ReactElement {
  const [code, setCode] = useState<string>("");
  const enabled = code.length >= 6;
  const onKeyDown = event => {
    if (enabled && event.keyCode === 13) {
      event.preventDefault();
      props.onRoomCodeEntered(code);
    }
  };
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Enter room code"
    customView={
      <StyledBoxColumn>
        <TextField
          autoFocus
          type="text"
          required
          onKeyDown={onKeyDown}
          inputProps={{
            style: {fontSize: "300%", fontWeight: "bold", textAlign: "center"},
            maxLength: ROOM_CODE_LENGTH,
          }}
          value={code}
          onChange={event => setCode(event.target.value?.trim().toUpperCase() || "")}/>
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          disabled={code.length < ROOM_CODE_LENGTH}
          variant="contained"
          onClick={() => props.onRoomCodeEntered(code)}>
          Join room
        </Button>
      </StyledBoxColumn>
    }/>;
}

abstract class BaseGameHelper {

  constructor(readonly path: PathProps) {
  }

  private renderDifficultyButton(level: GameDifficultyLevel) {
    return renderGameButton(new Action(level.text, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => this.showChooseWordDialog(level), DELAY);
    }));
  }

  private renderDifficultyButton2(level: GameDifficultyLevel) {
    return renderGameButton(new Action(level.text, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => this.onDifficultyLevelSelected(level), DELAY);
    }));
  }

  protected showChooseDifficultyDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose difficulty"
        customView={
          <StyledBoxColumn>
            {GameDifficultyLevel.VALUES.map(level => this.renderDifficultyButton(level))}
          </StyledBoxColumn>
        }/>
    );
  }

  protected showChooseDifficultyDialog2() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Choose difficulty"
        customView={
          <StyledBoxColumn>
            {GameDifficultyLevel.VALUES.map(level => this.renderDifficultyButton2(level))}
          </StyledBoxColumn>
        }/>
    );
  }

  private renderWordButton(level: GameDifficultyLevel, word: string) {
    return renderGameButton(new Action(word, () => {
      App.CONTEXT.hideDialog();
      setTimeout(() => this.onWordSelected(level, word), DELAY);
    }));
  }

  protected abstract onWordSelected(level: GameDifficultyLevel, word: string);

  protected abstract onDifficultyLevelSelected(level: GameDifficultyLevel);

  private async showChooseWordDialog(level: GameDifficultyLevel) {
    const count = 3;
    const words = new Array<string>(count);
    for (let i = 0; i < count; i++) {
      const word = (await GamesLoader.getInstance().getRandomWord(level)).trim().toUpperCase();
      if (word.length > MAX_LETTERS) {
        i--;
        continue;
      }
      let invalid = false;
      for (const letter of word.split("")) {
        if (letter.charCodeAt(0) < 'A'.charCodeAt(0) || letter.charCodeAt(0) > 'Z'.charCodeAt(0)) {
          invalid = true;
          break;
        }
      }
      if (invalid) {
        i--;
        continue;
      }
      let dupe = false;
      for (let j = i - 1; j >= 0; j--) {
        if (word === words[j]) {
          dupe = true;
          break;
        }
      }
      if (dupe) {
        i--;
        continue;
      }
      words[i] = word;
    }
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Pick a word"
        customView={
          <StyledBoxColumn>
            {words.map(word => this.renderWordButton(level, word))}
          </StyledBoxColumn>
        }/>
    );
  }

  protected createScrambled(word: string): string[] {
    const vowels = ["A", "E", "I", "O", "U"];
    const consonants = ["B", "C", "D", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "V", "W", "X", "Y", "Z"];
    const letters: string[] = [];
    for (const letter of word.split("")) {
      if (!letters.includes(letter)) {
        letters.push(letter);
      }
    }
    while (letters.length < MAX_LETTERS) {
      const randomLetter = Math.random() > 0.5 ? vowels[Math.floor(Math.random() * vowels.length)] : consonants[Math.floor(Math.random() * consonants.length)];
      if (!letters.includes(randomLetter)) {
        letters.push(randomLetter);
      }
    }
    const scrambled: string[] = [];
    while (letters.length > 0) {
      const letter = letters.splice(Math.floor(Math.random() * letters.length), 1)[0];
      scrambled.push(letter);
    }
    return scrambled;
  }
}

export class NewGameHelper extends BaseGameHelper {

  constructor(path: PathProps) {
    super(path);
  }

  createNewSoloGame() {
    this.showChooseDifficultyDialog2();
  }

  createNewChallengeGame() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <CustomDialogContent
        style={{minWidth: DW_XS, width: null}}
        title="Create game"
        customView={
          <StyledBoxColumn>
            {renderGameButton(new Action("Create room code", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => this.showChooseDifficultyDialog(), DELAY);
            }))}
            {renderGameButton(new Action("Enter room code", () => {
              App.CONTEXT.hideDialog();
              setTimeout(() => this.showEnterRoomCodeDialog(), DELAY);
            }))}
          </StyledBoxColumn>
        }/>
    );
  }

  static setDelayedRun(run: () => void) {
    setTimeout(() => run(), DELAY);
  }

  private showEnterRoomCodeDialog() {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
      <EnterRoomCodeView onRoomCodeEntered={code => {
        App.CONTEXT.hideDialog();
        setTimeout(() => this.joinAndShowNewRoom(code), DELAY);
      }}/>
    );
  }

  private async joinAndShowNewRoom(code: string) {
    const memberId = getMemberAuth().getMemberId();
    const room = await Rooms.getInstance().getOrLoadItem(code);
    if (!room) {
      App.CONTEXT.showToast("Room not found! Please try again.");
      return;
    }
    if (room.joinedBy && room.joinedBy !== memberId) {
      App.CONTEXT.showToast("Room is unavailable! Please try again.");
      return;
    }
    if (!room.joinedBy) {
      room.joinedBy = memberId;
      room.joinedAt = Date.now();
      room.joiner = getMemberAuth().member;
      await Rooms.getInstance().addListItem(room);
    }
    const join = await RoomJoins.getInstance().loadListItem(room.id);
    if (!join) {
      await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    }
    App.CONTEXT.showFullscreenDialog(() => <GameChallengeFragment path={this.path} initialRoom={room}/>);
  }

  protected onWordSelected(level: GameDifficultyLevel, word: string) {
    this.createAndShowNewRoom(level, word);
  }

  protected onDifficultyLevelSelected(level: GameDifficultyLevel) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN | DIALOG_FLAG_SHOW_CLOSE},
      () => <GameSoloFragment path={this.path} initialDifficultyLevel={level}/>);
  }

  private async createAndShowNewRoom(level: GameDifficultyLevel, word: string) {
    const room = new Room(
      passkey({
        length: 6,
        type: "alphabetic_upper"
      }),
      getMemberAuth().getMemberId(),
      Date.now())
      .updateSelf(true, level.name, word, this.createScrambled(word));
    await Rooms.getInstance().addListItem(room);
    await RoomJoins.getInstance().addListItem(RoomJoin.createNew(room.id));
    App.CONTEXT.showFullscreenDialog(() => <GameChallengeFragment path={this.path} initialRoom={room}/>);
  }
}


export class NewPageHelper extends BaseGameHelper {

  private callback: (level: GameDifficultyLevel) => void;

  constructor(path: PathProps, private readonly room: Room) {
    super(path);
  }

  protected onWordSelected(level: GameDifficultyLevel, word: string) {
    this.updateRoomInternal(level, word);
  }

  private async updateRoomInternal(level: GameDifficultyLevel, word: string) {
    await Rooms.getInstance().addListItem(this.room.clone<Room>(Room).updateSelf(true, level.name, word, this.createScrambled(word)));
  }

  updateRoom() {
    this.showChooseDifficultyDialog();
  }

  updateGame(callback: (level: GameDifficultyLevel) => void) {
    this.callback = callback;
    this.showChooseDifficultyDialog2();
  }

  protected onDifficultyLevelSelected(level: GameDifficultyLevel) {
    this.callback(level);
  }
}