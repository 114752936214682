import {ReactElement} from "react";
import {colorGreen, colorRed, colorYellow, mediumGray} from "../../shared/colors";
import {Typography} from "@mui/material";
import {StyledBoxRow} from "../../shared/StyledComponents";

export function ProgressView(props: {
  value0: number,
  value1: number,
  winCount: number,
  max: number,
  done?: boolean,
  win?: boolean
}): ReactElement {
  const height0 = 16;
  const height1 = 8;
  return <div style={{display: "flex", flexDirection: "column", position: "relative", width: "100%"}}>
    <div style={{display: "flex", position: "relative", width: "100%", height: height0}}>
      <div style={{
        position: "absolute",
        width: "100%",
        top: (height0 - height1) / 2,
        height: height1,
        background: "white"
      }}/>
      <div style={{
        position: "absolute",
        top: (height0 - height1) / 2,
        width: (Math.min(props.max, props.value0 + 1) * 100 / props.max) + "%",
        height: height1,
        background: mediumGray,
      }}/>
      <div style={{
        position: "absolute",
        top: (height0 - height1) / 2,
        width: (Math.min(props.max, props.value1) * 100 / props.max) + "%",
        height: height1,
        background: props.done ? (props.win ? colorGreen : colorRed) : colorYellow,
      }}/>
    </div>
    <StyledBoxRow style={{color: "white"}}>
      <Typography variant="caption">{props.value1} correct.</Typography>
      <Typography variant="caption">{props.winCount} to win.</Typography>
    </StyledBoxRow>
  </div>;
}